.popup-credentials-mnemonic-field {
	width: 100%;
	background: #f5f5f5;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	border-radius: 15px;
	padding: 1em 0em;
	display: flex;
}
