@media print {
	@page {
		size: a4 !important;
		margin: 0 !important;
	}

	html,
	body {
		width: 210mm !important;
		height: 297mm !important;
	}

	body {
		zoom: 50%;
		margin: 0 !important;
	}

	.table-to-print {
		table:not(.table-info, .table-checkin560, .table-note, .table-signature, .table-footer) {
			page-break-before: always;
		}

		tr,
		td {
			page-break-inside: avoid;
		}
	}

	tfoot {
		display: table-footer-group !important;
	}
}

.print-button {
	color: #149cd8 !important;
	background-color: white !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	border-radius: 15px !important;
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 28px !important;
	line-height: 42px !important;
	padding: 0.25em 1.5em !important;

	&:hover {
		background-color: #149cd8 !important;
		color: white !important;
	}
}