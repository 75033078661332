input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.input-form {
	&.multiline {
		height: fit-content !important;
	}

	& .MuiInputBase-root {
		background-color: transparent !important;
	}

	&>* {
		background-color: transparent !important;
	}

	& input,
	.MuiSelect-select {
		-webkit-appearance: none;
		-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		border-radius: 15px !important;
		background: transparent;
	}

	&.gray-background input,
	&.gray-background .MuiSelect-select,
	&.gray-background .MuiInputBase-multiline {
		background: #f5f5f5 !important;
		background-color: #f5f5f5 !important;
	}

	& .MuiInputBase-multiline {
		align-items: flex-start !important;
		-webkit-appearance: none;
		-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		border-radius: 15px !important;
		min-height: 150px;
	}

	& label {
		background-color: transparent !important;
	}

	& .MuiInputBase-root::before,
	.MuiInputBase-root::after {
		border-bottom: 0px !important;
	}

	& p {
		background-color: #f5f5f5 !important;
		margin: 0;
	}

	& .MuiFormHelperText-root {
		background-color: transparent !important;
		padding-left: 12px;
	}

	&.login-password-field {
		input {
			-webkit-box-shadow: unset !important;
			box-shadow: unset !important;
		}
	}
}

.input-form-container-crew-member {
	& .MuiGrid-root {
		padding-left: 8px;
		padding-right: 8px;
	}
}

.input-date-container {
	-webkit-appearance: none;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	background: transparent;
	border-radius: 15px !important;
	padding-top: 25px;
	padding-right: 12px;
	padding-bottom: 8px;
	padding-left: 12px;
	position: relative;

	.input-date-label {
		position: absolute;
		color: #000000;
		font-weight: 400;
		font-size: 16px;
		transform: translate(12px, 7px) scale(0.75);
		transform-origin: top left;
		font-family: Poppins;
		line-height: 1.4375em;
		top: 0;
		left: 0;
		transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
			max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	}

	.input-date-label:focus-visible {
		color: #149cd8;
	}

	.input-date {
		width: 90%;
		border: 0;
		outline: 0;
		background: transparent;
	}

	.react-datepicker__tab-loop+.input-date-label {
		color: #149cd8;
	}

	.input-date-error {
		position: absolute;
		color: red;
		font-size: 17px;
		transform: translate(12px, 7px) scale(0.75);
		transform-origin: bottom left;

		bottom: -15px;
		left: -12px;
	}

	.react-datepicker-popper {
		z-index: 100;
	}
}

.checklist-elemenet-input {
	display: flex;
	justify-content: flex-end;
	width: 25%;
	min-width: 150px;

	.text-choice-checklist {
		display: flex;
		justify-content: space-between;
		align-items: center;

		height: 40px !important;

		& label {
			font-size: 12px !important;
		}

		& .MuiInputBase-root {
			height: 100% !important;
		}

		& input {
			font-size: 12px !important;
			padding-top: 12px !important;
			padding-right: 12px !important;
			padding-bottom: 4px !important;
			padding-left: 12px !important;
		}
	}

	@media (max-width: 1000px) {
		width: 20%;
	}

	@media (min-width: 751px) {
		justify-content: flex-start;
		margin-right: 15%;
	}

	@media (max-width: 750px) {
		margin-right: 16px;
		margin-left: 16px;
		max-width: 65px;
		min-width: 65px;
	}

	@media (max-width: 500px) {
		margin-right: 4px;
		margin-left: 4px;
		max-width: 45px;
		min-width: 45px;
	}
}

.checklist-titles {
	display: flex;
	width: 25%;
	min-width: 150px;

	&>h5 {
		@media (max-width: 750px) {
			text-align: center;
		}
	}

	@media (max-width: 1000px) {
		width: 20%;
	}

	@media (min-width: 751px) {
		margin-right: 15%;
	}

	@media (max-width: 750px) {
		max-width: 65px;
		min-width: 65px;
		flex-direction: column;
		margin-right: 16px;
		margin-left: 16px;
	}

	@media (max-width: 500px) {
		max-width: 45px;
		min-width: 45px;
		margin-right: 4px;
		margin-left: 4px;
	}
}

.checklist-checkout-elemenet-input {
	display: flex;
	justify-content: flex-end;
	width: 25%;
	min-width: 150px;

	.text-choice-checklist {
		display: flex;
		justify-content: space-between;
		align-items: center;

		height: 40px !important;

		& label {
			font-size: 12px !important;
		}

		& .MuiInputBase-root {
			height: 100% !important;
		}

		& input {
			font-size: 12px !important;
			padding-top: 12px !important;
			padding-right: 12px !important;
			padding-bottom: 4px !important;
			padding-left: 12px !important;
		}
	}

	@media (max-width: 1000px) {
		width: 20%;
	}

	@media (min-width: 751px) {
		justify-content: flex-start;
		margin-right: 32px;
	}

	@media (max-width: 750px) {
		margin-right: 16px;
		margin-left: 16px;
		max-width: 65px;
		min-width: 65px;
	}

	@media (max-width: 500px) {
		margin-right: 6px;
		margin-left: 6px;
		max-width: 45px;
		min-width: 45px;
	}
}

.checklist-checkout-titles {
	display: flex;
	width: 25%;
	min-width: 150px;

	&>h5 {
		@media (max-width: 750px) {
			text-align: center;
		}
	}

	@media (max-width: 1000px) {
		width: 20%;
	}

	@media (min-width: 751px) {
		margin-right: 32px;
	}

	@media (max-width: 750px) {
		max-width: 65px;
		min-width: 65px;
		flex-direction: column;
		margin-right: 16px;
		margin-left: 16px;
	}

	@media (max-width: 500px) {
		max-width: 45px;
		min-width: 45px;
		margin-right: 6px;
		margin-left: 6px;
	}
}