.rating-container {
	#rating-container-title {
		font-size: 40px;

		@media (max-width: 400px) {
			font-size: 21px;
		}
	}

	#rating-categories-container {
		margin-top: 2em;
		margin-bottom: 0.2em;

		@media (max-width: 450px) {
			margin-top: 1.2em;
		}
	}

	.MuiRating-sizeLarge {
		margin-top: 0.2em;
		margin-bottom: 0.2em;

		@media (min-width: 451px) {
			font-size: 3.5em !important;
		}
		@media (min-width: 950px) {
			font-size: 4em !important;
		}
		@media (min-width: 1120px) {
			font-size: 5em !important;
		}
		@media (max-width: 450) {
			font-size: 1em !important;
		}
	}
}
