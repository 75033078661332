.gais-logo {
	position: absolute;
	top: 16px;
	left: 48px;

	max-width: 40%;
	width: 150px;
	aspect-ratio: 1/1;
	@media (max-width: 1200px) {
		width: 90px;
		left: 24px;
	}
	@media (max-width: 700px) {
		width: 80px;
	}
}
.login-container .change-language-buttons {
	top: 16px !important;
	bottom: unset;
	left: unset;
	right: 16px !important;
}
.login-background {
	position: fixed;
	min-width: 100%;
	min-height: 100%;
	z-index: -1;
}
#login-form {
	max-width: 1000px;
	#login-title {
		color: white;
		white-space: nowrap;
		text-align-last: justify;
		overflow: unset;
	}
}

.wave-animation {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: url("../assets/wave_login.png");
	background-size: 70% 100px;
	z-index: 100;
	opacity: 1;
	animation: animate 15s linear infinite;
	@media (max-width: 700px) {
		background-size: 90% 100px;
	}
}

#password-input-field {
	label {
		@media (max-width: 1200px) {
			font-size: 14px !important;
		}
		@media (max-width: 700px) {
			font-size: 8px !important;
		}
	}
	input {
		@media (max-width: 1200px) {
			font-size: 14px !important;
		}
		@media (max-width: 700px) {
			font-size: 12px !important;
		}
	}
}
#username-input-field,
#email-input-field {
	label {
		@media (max-width: 1200px) {
			font-size: 14px !important;
		}
		@media (max-width: 700px) {
			font-size: 8px !important;
		}
	}
	input {
		@media (max-width: 1200px) {
			font-size: 14px !important;
		}
		@media (max-width: 700px) {
			font-size: 12px !important;
		}
	}
}
@keyframes animate {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: 1000px;
	}
}
