#root {
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 16px;
	border-radius: 16px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 16px;
}
::-webkit-scrollbar-thumb {
	background: #c9c7c7;
	border-radius: 16px;
	&:hover {
		background: #c9c7c7a6;
	}
}

.accordion {
	background: #f5f5f5 !important;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	border-radius: 15px !important;
	&::before {
		height: 0 !important;
	}
	.MuiAccordionSummary-content {
		h4 {
			font-weight: 600 !important;
			font-size: 20px !important;
			line-height: 32px !important;
		}
		max-width: 100%;
	}
}
.MuiDialog-container {
	& .MuiDialog-paper {
		background: #e3e3e3 !important;
		border-radius: 8px !important;
	}
}

.accordion-extras {
	background: transparent !important;
	-webkit-box-shadow: unset !important;
	box-shadow: unset !important;
}
.MuiTypography-h3 {
	@media (max-width: 600px) {
		font-size: 24px !important;
	}
}

.MuiTypography-h4 {
	@media (max-width: 600px) {
		font-size: 14px !important;
	}
}
.MuiTypography-h5 {
	@media (max-width: 600px) {
		font-size: 10px !important;
	}
	@media (max-width: 500px) {
		display: inline-block;

		overflow: hidden !important;
		word-wrap: break-word !important;
	}
}

.MuiTypography-body1 {
	@media (max-width: 600px) {
		font-size: 9.5px !important;
	}
}
.MuiTypography-body2 {
	@media (max-width: 600px) {
		font-size: 9.5px !important;
	}
}
.info-circle {
	aspect-ratio: 1/1;
	height: 25px;
	@media (max-width: 600px) {
		height: 14px;
	}
}
.info-circle-landing {
	aspect-ratio: 1/1;
	height: 22px;
	@media (max-width: 600px) {
		height: 12px;
	}
}
.rounded-container {
	background: #f5f5f5;
	-webkit-appearance: none;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	border-radius: 15px !important;
	color: #149cd8;
	padding: 20px 26px;
}
