.checkbox-section-to-be-removed {
	background: #f5f5f5;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 6px 12px;
	margin: 0 !important;
	h5 {
		color: rgba(0, 0, 0, 0.7);
	}
}

.enable-articles-container {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
	.enable-articles-button {
		width: calc(50% - 8px);
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 24px !important;
		line-height: 36px !important;
		color: rgba(0, 0, 0, 0.7) !important;
		background-color: #f5f5f5 !important;
		padding: 14px 1.5rem !important;
		&:hover {
			color: rgba(0, 0, 0, 0.7) !important;
			background-color: #f5f5f5 !important;
		}
		&.enable-article-selected {
			color: white !important;
			background-color: #149cd8 !important;
		}
	}
}
