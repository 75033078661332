.tutorial-container {
	padding: 8px;

	.tutorial-wrapper {
		background: #d9d9d9;
		border-radius: 7px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 16px;
		> div {
			height: 100%;
			width: 100%;
		}
		iframe {
			width: 100%;
		}
	}
}
