.snackbar-loading {
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	justify-content: center;
	align-items: center;
	min-width: 200px;
	background-color: #e3e3e3;
	border-radius: 6px;
}
