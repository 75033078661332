.button {
	font-size: 28px !important;
	padding: 0.25em 1.5em !important;

	@media (max-width: 900px) {
		font-size: 18px !important;
	}

	@media (max-width: 600px) {
		font-size: 14px !important;
		padding: 0em 0.75em !important;
	}
}

.form-button {
	background-color: #149cd8 !important;
	color: white !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	border-radius: 15px !important;
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 28px !important;
	line-height: 42px !important;
	padding: 0.25em 1.5em !important;

	&:hover {
		color: #149cd8 !important;
		background-color: white !important;
	}

	&.payment-choice {
		font-weight: 400 !important;
		border-radius: 9px !important;
		font-size: 24px !important;
		line-height: 36px !important;

		@media (max-width: 900px) {
			width: 100% !important;
			font-size: 14px !important;
			line-height: 14px;
		}

		@media (max-width: 600px) {
			font-size: 9.5px !important;
			line-height: 14px;

			padding: 0em 0.75em !important;
		}

		color: #149cd8 !important;
		background-color: white !important;

		&:hover {
			background-color: #149cd8 !important;
			color: white !important;
		}

		&.choice-selected {
			color: white !important;
			background-color: #149cd8 !important;

			&:hover {
				background-color: white !important;
				color: #149cd8 !important;
			}

			&:focus {
				color: white !important;
				background-color: #149cd8 !important;
			}
		}
	}

	&.subbutton {
		text-transform: none !important;
		font-weight: 400 !important;
		font-size: 24px !important;
		line-height: 36px !important;
	}

	&.check-images {
		font-weight: 400 !important;
		font-size: 24px !important;
		line-height: 36px !important;
		color: black !important;
		background-color: white !important;

		&:hover {
			background-color: #149cd8 !important;
			color: white !important;
		}
	}

	&.admin-button {
		@media (max-width: 900px) {
			font-size: 26px !important;
		}

		@media (max-width: 600px) {
			font-size: 24px !important;
		}
	}
}

.button-forgot-password {
	color: white !important;
	background-color: transparent !important;
	font-weight: 600 !important;
	font-size: 24px !important;
	line-height: 36px !important;
	-webkit-box-shadow: unset !important;
	box-shadow: unset !important;

	&:hover {
		color: #149cd8 !important;
	}
}

.payment-button-container {
	@media (min-width: 900px) {
		flex-grow: unset !important;
		flex-basis: unset !important;
	}
}

.settings-download-button {
	background: #f5f5f5 !important;
	color: black !important;
	padding: 0.35em 2.5em !important;

	-webkit-appearance: none;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

	border-radius: 15px !important;
	font-weight: 600 !important;
	font-size: 24px !important;
	line-height: 36px !important;

	@media (max-width: 600px) {
		width: 100%;
		padding: 0 !important;
	}
}

.menu-button {
	button:hover {
		color: white !important;
	}
}

.order-check-in-button {
	color: #149cd8 !important;
	background-color: white !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	max-width: 280px;

	&:hover {
		background-color: #149cd8 !important;
		color: white !important;
	}

	@media (max-width: 600px) {
		font-size: 18px !important;
		line-height: 28px !important;
	}
}

.check-in-submit-button {
	@media (max-width: 600px) {
		padding: 0.7em 3em !important;
		font-weight: 700 !important;
		font-size: 14px !important;
		line-height: 21px !important;
	}
}