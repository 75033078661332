.damage-container {
	background: #f5f5f5;
	-webkit-appearance: none;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	border-radius: 15px !important;
	padding: 20px 26px;

	max-width: 1000px;
	width: fit-content;
	transition: all 1s ease-out;
	margin-bottom: 10px;

	@media (max-width: 600px) {
		padding: 12px 18px;
		h5 {
			display: block;
		}
	}
	& .damage-text {
		overflow: hidden;
		// width: 0;

		max-height: 0;
		transition: all 1s ease-out;
	}
	&:hover {
		transition: all 1s ease-out;
		width: auto;
		.damage-text {
			width: 100%;
			max-height: 500px;
			transition: all 1s ease-out;
		}
	}
}
