.pending-action {
	color: #149cd8 !important;
}

.container-order-check-in-buttons {
	@media (max-width: 600px) {
		justify-content: center;
		align-items: center;
	}
}

.order-check-in-document-checkbox {
	color: #8e8e8e;
	background-color: #e3e3e3;
	border-radius: 8px;
	width: 100%;
	aspect-ratio: 1/1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.order-check-in-document-checkbox-uploaded {
	color: white;
	background-color: #149cd8;
}

.check-in-document-icon {
	pointer-events: none;
	transform: scale(4);
	height: 4em !important;

	@media (max-width: 1000px) {
		transform: scale(3);
		height: 3em !important;
	}

	@media (max-width: 600px) {
		transform: scale(2);
		height: 2em !important;
	}
}

.order-checkbox {
	color: #8e8e8e;
	background-color: #e3e3e3;
	width: 40px;
	min-width: 40px;
	height: 40px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;

	.checkbox-icon {
		transform: scale(1.5);
	}

	&.checkbox-checklist {
		.checkbox-icon {
			transform: scale(1.2);

			@media (max-width: 600px) {
				transform: scale(0.8);
			}
		}

		@media (max-width: 1000px) {
			min-width: unset;
			width: 35px;
			height: 35px;
		}

		@media (max-width: 600px) {
			min-width: unset;
			width: 30px;
			height: 30px;
		}

		@media (max-width: 600px) {
			width: 20px;
			height: 20px;
		}
	}
}

.order-checkbox-filled {
	color: white;
	background-color: #149cd8;
}

.order-checklist-info{
	display: flex;
	align-items: center;
	width: 100%;
	z-index: 1;
	cursor: pointer;
}

.order-extra-transfer-field-container {
	& {
		padding-right: 16px !important;

		@media (max-width: 600px) {
			padding-right: 0px !important;
		}
	}

	&.last {
		padding-right: 0 !important;
	}

	.order-extra-transfer-field {
		background-color: transparent !important;
		width: 100%;

		& label {
			background-color: transparent !important;
			color: rgba(0, 0, 0, 0.7) !important;
		}

		& .MuiInputBase-input,
		& .MuiInputBase-multiline {
			-webkit-appearance: none;
			-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

			border-radius: 15px !important;
			background: transparent;
		}

		& .MuiInputBase-root {
			background-color: transparent !important;
		}

		& p {
			background-color: #f5f5f5 !important;
			margin: 0;
		}

		& .MuiFormHelperText-root {
			background-color: transparent !important;
		}

		& .MuiInputBase-root::before,
		.MuiInputBase-root::after {
			border-bottom: 0px !important;
		}

		&.multiline {
			& .MuiInputBase-input {
				-webkit-appearance: none;
				-webkit-box-shadow: unset;
				box-shadow: unset;

				border-radius: unset !important;
			}
		}
	}
}

.popup-user-credentials-container {
	background-color: transparent;
	-webkit-appearance: none;
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

	height: 64px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	padding-left: 18px;
}

.extra-summary-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: flex-start;
	}

	.MuiTypography-root {
		@media (max-width: 600px) {
			font-size: 9.5px !important;
		}
	}
}

.accordion-extras {
	margin: 0px !important;

	.MuiTypography-root {
		@media (max-width: 600px) {
			font-size: 9.5px !important;
		}
	}

	& .MuiAccordionSummary-content {
		margin: 0px !important;
		padding: 8px 8px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 0px;
	}
}

.extra-component-container {
	& .MuiAccordionSummary-root {
		padding: 0px;
		min-height: unset;
	}

	& .accordion-summary-icon {
		@media (max-width: 600px) {
			width: 0.7em;
			position: relative;
		}
	}

	& .extra-summary-piece-subcontainer-right {
		@media (max-width: 600px) {
			position: relative;
		}
	}
}

.generic-extra-content {
	margin-right: 1em;

	@media (max-width: 600px) {
		margin-right: 0.7em;
	}
}

.order-accordion {
	@media (max-width: 600px) {
		padding: 8px 8px 16px !important;
	}
}

.checklist-choice-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	min-width: 150px;
	max-width: 150px;
	gap: 24px;

	@media (max-width: 750px) {
		max-width: 65px;
		min-width: 65px;
		gap: 2px;
	}

	@media (max-width: 500px) {
		max-width: 45px;
		min-width: 45px;
	}
}

.yes-no-text-checklist-check-mobile-container {
	display: none;

	&>h5 {
		min-width: 20px;
		text-align: center;

		@media (max-width: 500px) {
			min-width: unset;
		}
	}

	@media (max-width: 750px) {
		display: flex;
		justify-content: space-around;
		margin-right: 4px;
		margin-left: 4px;
	}
}

.yes-no-text-checklist-check-desktop {
	@media (max-width: 750px) {
		display: none;
	}
}

.check-in-section-rejected-message {
	font-size: 18px !important;
	word-break: break-all;

	@media (max-width: 1000px) {
		font-size: 12px !important;
	}
}