.landing {
	display: flex;
	justify-content: center;
	h3 {
		white-space: normal !important;

		@media (max-width: 700px) {
			font-size: 20px !important;
		}
	}
	.form-button {
		@media (max-width: 700px) {
			white-space: nowrap;
			font-size: 18px !important;
		}
	}
	.settings-download-button {
		@media (max-width: 700px) {
			white-space: nowrap;
			font-size: 16px !important;
		}
	}
	.landing-images-conatainer {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		padding: 0 4px;
		margin-bottom: 42px;
		@media screen and (max-width: 750px) {
			padding: 0px;
		}
	}

	.column-images {
		flex: 25%;
		max-width: 50%;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		&:first-child {
			padding-left: 0px;
		}
		&:last-child {
			padding-right: 0px;
		}
		@media screen and (max-width: 750px) {
			padding: 0px;
		}
	}

	.column-images .landing-step {
		flex: 1;
		margin-top: 32px;
		vertical-align: middle;
		width: 100%;
		display: flex;
		position: relative;
		img {
			flex: 1;
			width: 100%;
		}
		.image-content {
			position: absolute;
			top: 0;
			left: 0;
			padding: 7%;
			h3,
			p {
				white-space: unset !important;
			}
			@media (max-width: 700px) {
				padding: 1em;
			}
		}
	}
	.default-bar {
		background: #f5f5f5;
		-webkit-appearance: none;
		-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		border-radius: 15px !important;
		color: #149cd8;
		padding: 20px 26px;
		@media (max-width: 600px) {
			padding: 12px 18px;
			h5 {
				display: block;
			}
		}
	}
	@media screen and (min-width: 1920px) {
		.column-images {
			max-width: 50%;
		}
	}
	@media screen and (max-width: 1500px) {
		.column-images {
			max-width: 50%;
		}
	}

	@media screen and (max-width: 800px) {
		.column-images {
			flex: 100%;
			max-width: 100%;
		}
	}
	@media screen and (max-width: 750px) {
		.column-images {
			flex: 100%;
			max-width: 100%;
		}
	}
	@media screen and (max-width: 600px) {
		.column-images {
			flex: 100%;
			max-width: 100%;
		}
	}
}

#first-image {
	@media screen and (max-width: 600px) {
		& {
			min-height: 180px;
		}
	}
}
#second-image {
	@media screen and (max-width: 600px) {
		& {
			min-height: 270px;
		}
	}
}
#third-image {
	@media screen and (max-width: 600px) {
		& {
			min-height: 290px;
		}
	}
}
#fourth-image {
	min-height: 220px;

	@media screen and (max-width: 600px) {
		& {
			min-height: 200px;
		}
	}
}

.landing-deposit-info {
	.landing-deposit-hover {
		margin-top: 0px;
		max-height: 0px;
		overflow: hidden;
		transition: all 0.5s ease;
	}
	&:hover {
		.landing-deposit-hover {
			margin-top: 16px;
			max-height: 200px;
			transition: all 0.5s ease;
		}
	}
}
