.input-order-checklist {
	width: 25px;
	padding: 8px;
	padding-top: 5px;
	padding-bottom: 5px;
	background: #e3e3e3;
	border-radius: 8px;
	border: unset !important;
	text-align: center;
}

.icon-checklist {
	cursor: pointer;
	border-radius: .5em;
	display: flex;
	transition: all .2s ease-in-out;
}

.icon-checklist:hover {
	background-color: #959595;
}

.icon-checklist:hover svg {
	color: white;
}