.document-element {
	box-sizing: border-box;

	border: 5px dashed #f5f5f5;
	height: 236px;

	margin-top: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #e3e3e3;
	border-radius: 15px;

	&.document-element-selected {
		background-color: #149cd8;
		border: 0;
		color: white;
	}

	& .check-in-document-icon {
		pointer-events: none;

		transform: scale(6);
		height: 6em !important;
	}
}

.container-document-option {
	position: absolute;
	bottom: 13%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
}