$width-small-mobile: 70px;
$width-background-small-mobile: 80px;

$width-mobile: 110px;
$width-background-mobile: 110px;

$width-desktop: 175px;
$width-desktop-open: 360px;
$width-tablet-open: 320px;

$mask-position-mobile: 100% 60%;
$mask-position-mobile-open: 100% 100%;
$mask-position-desktop: 100% 60%;
$mask-position-desktop-open: 100% 0%;

// .toggle-menu-button {
//
// 	border: solid black;
// 	border-width: 0 3px 3px 0;

// 	left: calc($width-desktop / 2);
// 	margin-top: auto;
// 	margin-bottom: auto;

// 	top: 45%;
// 	z-index: 1000;
// 	transform: translate(45%, 0%) rotate(-45deg);
// 	-webkit-transform: translate(45%, 0%) rotate(-45deg);
// 	@media (max-width: 700px) {
// 		left: $width-mobile;
// 	}
// 	@media (max-width: 350px) {
// 		left: $width-small-mobile;
// 	}
// }

.menu {
	position: fixed;
	display: flex;
	flex-direction: column !important;
	justify-content: flex-start;
	min-height: 100vh;
	overflow: hidden;
	width: $width-desktop;
	z-index: 100;

	max-width: 100vw;
	transition: width 0.5s ease-in-out;

	@media (max-width: 700px) {
		width: $width-mobile;
	}
	@media (max-width: 350px) {
		width: $width-small-mobile;
	}

	#menu-open-arrow {
		position: absolute;
		width: 60px;
		height: 60px;
		background: url(../assets/open_menu_icon.svg);
		right: 45px;
		top: 50%;
		background-size: cover;
		transform: translate(50%, 0%);
		-webkit-transform: translate(50%, 0%);
		opacity: 1;
		transition: all 0.5s ease-in-out;
		@media (max-width: 700px) {
			width: 35px;
			height: 35px;
			right: 35px;
		}
	}
	&:hover {
		#menu-open-arrow {
			opacity: 0;
			transition: all 0.5s ease-in-out;
			transform: translate(50%, 0%) rotate(-90deg);
		}
	}
}

.menu:hover {
	width: $width-desktop-open;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1200px) {
		width: $width-tablet-open;
	}
	@media (max-width: 700px) {
		width: 100%;
	}
}

.menu-background {
	position: absolute;
	top: 0;
	left: -4px;
	min-height: 100vh;
	z-index: -10;
	background-color: #149cd8;
	width: $width-desktop;
	transition: all 0.5s ease-in-out;

	@media (max-width: 700px) {
		width: $width-background-mobile;
	}
	@media (max-width: 350px) {
		width: $width-background-small-mobile;
	}
}

.menu .menu-background::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 4px;
	mask-size: auto 120%;
	mask-position: $mask-position-desktop;

	mask-image: url(../assets/wave_menu.png);

	background-color: white;
	mask-repeat: no-repeat;
	transition: all 0.5s ease-in-out;
	@media (max-width: 700px) {
		mask-position: $mask-position-mobile;
	}
}

.menu:hover .menu-background {
	width: $width-desktop-open;
	transition: all 0.5s ease-in-out;

	@media (max-width: 1200px) {
		width: $width-tablet-open;
	}
	@media (max-width: 700px) {
		width: 1500px;
	}
}
.menu:hover .menu-background::after {
	mask-position: $mask-position-desktop-open;
	transition: all 0.5s ease-in-out;
	@media (max-width: 700px) {
		mask-position: $mask-position-mobile-open;
	}
}

.logo-menu {
	width: 40px;
	height: 40px;
	padding: 4px;
	background-color: white;
	border-radius: 50%;
	@media (max-width: 700px) {
		width: 30px;
		height: 30px;
	}

	&.user-menu-logo {
		&:hover {
			cursor: pointer;
		}
	}
}

.menu-button {
	max-width: 260px;
	padding-left: 1em;
	z-index: -1000;
	white-space: nowrap;
	opacity: 0;
	transition: all 0.5s ease-in-out;

	button {
		font-size: 24px !important;
		padding: 11px 58px !important;
		@media (max-width: 1200px) {
			font-size: 22px !important;
			padding: 6px 58px !important;
		}
		@media (max-width: 700px) {
			font-size: 18px !important;
			min-width: 260px;
			padding: 2.5px 58px !important;
		}
	}
	@media (max-width: 1200px) {
		max-width: 230px;
	}
	@media (max-width: 700px) {
		max-width: 260px;
		padding-left: unset;
		margin: auto !important;
	}
}
.menu:hover .menu-button {
	transition: all 0.5s ease-in-out;

	opacity: 1;
	z-index: 1000;
}

.menu-buttons-container {
	@media (max-width: 700px) {
		min-width: 100vw;
		justify-content: center !important;
	}
}
.icon-close-mobile {
	opacity: 0;
	z-index: -10000;
	transition: z-index 0.5s ease-in-out;
}
.icon-close-mobile-container {
	background: #d9d9d9;
	opacity: 0;
	z-index: -10000;
	position: fixed;
	right: 2em;
	top: 0;
	transition: z-index 0.5s ease-in-out;
	border-radius: 50%;
	width: 50px;
	height: 50px;
}
.menu:hover + .icon-close-mobile-container {
	transition: z-index 0.5s ease-in-out;

	z-index: 10000;
	.icon-close-mobile {
		@media (max-width: 700px) {
			transition: z-index 0.5s ease-in-out;
			opacity: 1;
			z-index: 10000;
		}
	}
	@media (max-width: 700px) {
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.header-name-menu {
	opacity: 0;
	margin-left: 16px !important;
	font-size: 20px !important;
	@media (max-width: 1200px) {
		font-size: 16px !important;
	}
}
.header-menu-subtitle {
	font-weight: 300 !important;
	font-size: 12px !important;
	@media (max-width: 1200px) {
		font-size: 10px !important;
	}
}

.menu:hover .header-name-menu {
	opacity: 1;
}

.menu-header {
	padding-left: 1em;
	margin-top: 24px;
	.header-menu-name {
		max-width: calc($width-desktop-open - 150px);
		h4,
		p {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		@media (max-width: 1200px) {
			max-width: calc($width-tablet-open - 140px);
		}
		@media (max-width: 700px) {
			max-width: 100%;
		}
	}
}

.change-language-buttons {
	position: fixed;
	width: $width-desktop !important;
	bottom: 16px;
	left: 0;
	margin-left: 1em;

	.flag-container {
		display: flex;
		padding: 0.4em;
		background-color: transparent;
		border-radius: 50%;
		border: #f5f5f585 solid;
		width: 25px;
		height: 25px;
		@media (max-width: 700px) {
			width: 20px;
			height: 20px;
		}
	}
	.active-language {
		.flag-container {
			background-color: #f5f5f585;
		}
	}
	.memu & > div:has(.active-language) {
		display: flex;
	}
	.menu & > div:not(.active-language) {
		display: none;
	}
	.menu:hover & > div {
		display: flex !important;
	}
}

.menu-content {
	margin-top: 70px !important;
	padding-left: 10px;
	padding-right: 10px;
}
